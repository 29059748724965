"use strict";

//Modules

//Components
import Slider from './components/slider';
import Header from './components/header';
import Contato from './components/form_contato';
import Produtos from './components/produtos';

export default class App {
    constructor(){

        new Header();
        
        //Templates
        const SliderTemplate = document.querySelectorAll('.sliderVitrine');
        if (SliderTemplate.length > 0) {
            new Slider();
        }

        //Contato
        const formMarca = document.querySelectorAll('.template-contato')
        if(formMarca.length > 0) {
            new Contato();
        }

        //Produto
        const ModuloProduto = document.querySelectorAll('.produto')
        if(ModuloProduto.length > 0) {
            new Produtos()
        }

    }
}

new App();