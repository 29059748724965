export default class Header {
    constructor() {

        this.header = document.querySelector('header')
        this.btnMenuMobile = document.querySelector('#btnmenuMobile')
        this.menuMobile = document.querySelector('#menuMobile')
        this.menu = document.querySelector('.menuMobile')
        this.menuItems = document.querySelectorAll('.colMenu ul li a')
        this.menuItemsMobile = document.querySelectorAll('#menuMobile ul li a')

        this.ready();
    }

    ready() {

        document.addEventListener('scroll', (e) => {
            let scrollBarPosition = window.scrollY
            if(scrollBarPosition > 0) {
                this.header.classList.add('scroll')
            } else {
                this.header.classList.remove('scroll')
            }
        })

        if(window.home === 'sim') {
            this.menuItems.forEach(element => {
                const position = document.getElementById(element.dataset.link).offsetTop - 50
                element.addEventListener("click", () => {
                    window.scrollTo({
                        top: position,
                        behavior: "smooth"
                    });
                });
            });
        }


		this.btnMenuMobile.addEventListener("click", () => {
			this.btnMenuMobile.classList.toggle("active");
			this.menuMobile.classList.toggle("active");
			this.menu.classList.toggle("active");

            if(window.home === 'sim') {
                this.menuItemsMobile.forEach(element => {
                    const position = document.getElementById(element.dataset.link).offsetTop - 50
                    element.addEventListener("click", () => {

                        this.btnMenuMobile.classList.remove("active");
                        this.menuMobile.classList.remove("active");
                        this.menu.classList.remove("active");

                        window.scrollTo({
                            top: position,
                            behavior: "smooth"
                        });

                    });
                });
            }

		});
        
    }
}