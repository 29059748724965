import Swiper, { Pagination, Navigation } from "swiper";

export default class Produtos {
    constructor() {

        this.slider = document.querySelector(".swiper--produtos");

        this.ready();
    }

    ready() {

        const swiper = new Swiper(".swiper--produtos", {
            modules: [Pagination, Navigation],
            grabCursor: true,
            slidesPerView: 1,
            allowTouchMove: true,
            navigation: {
                nextEl: ".slider-button-next--produtos",
                prevEl: ".slider-button-prev--produtos",
            },
        });
        
    }
}