import { tns } from "tiny-slider";

export default class Slider {
    constructor() {
        this.ready();
    }

    ready() {

        const tnsCarousel = document.querySelectorAll(".sliderVitrine");
        if (tnsCarousel && !tnsCarousel.length) return false;

        tnsCarousel.forEach(slider => {
            const sliderCurrent = tns({
                loop: false,
                container: slider,
                mouseDrag: true,
                controls: true,
                nav: true,
                autoHeight: false,
                controlsText: ['<svg width="20" height="38" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.498 37.376c-.64 0-1.28-.26-1.767-.782L.732 20.576c-.976-1.043-.976-2.733 0-3.776l15-16.018a2.388 2.388 0 0 1 3.534 0c.977 1.043.977 2.732 0 3.775L6.034 18.688l13.234 14.133c.976 1.043.976 2.732 0 3.775a2.413 2.413 0 0 1-1.77.78Z" fill="#fff"/></svg>', '<svg width="20" height="38" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.5 37.376c-.64 0-1.28-.26-1.768-.782-.976-1.043-.976-2.733 0-3.775l13.236-14.131L.732 4.558c-.976-1.044-.976-2.733 0-3.776a2.388 2.388 0 0 1 3.536 0l15 16.019c.976 1.043.976 2.732 0 3.775l-15 16.018a2.404 2.404 0 0 1-1.768.782Z" fill="#fff"/></svg>'],
                onInit: (sliderInstance) => {
                    sliderInstance.container.classList.remove("invisible");
                }
            });
        });

        
    }
}