import axios from 'axios'
import * as VMasker from "vanilla-masker"

export default class Contato {
    constructor() {

        this.form = document.querySelector('#formContato')
        this.btnSubmitForm = document.querySelector('#btnEnviarContato')
        this.errorForm = document.querySelector('.erroContato')
        this.sucessoForm = document.querySelector('.sucessoContato')

        this.fieldName = document.querySelector('#nome')
        this.fieldEmail = document.querySelector('#email')
        this.fieldPhone = document.querySelector('#telefone')
        this.fieldSubject = document.querySelector('#assunto')
        this.fieldMessage = document.querySelector('#mensagem')

        this.telMask = ['(99)99999-9999'];

        this.events()

    }
    
    sendForm() {
      
        const dataForm = new FormData(this.form)
    
        axios({
            method: "post",
            url: window.location.protocol + "//" + window.location.host+"/email/form_contato.php",
            data: dataForm,
            headers: { "Content-Type": "multipart/form-data" },
        })
            .then((data) => {
                this.form.reset()
                this.sucessoForm.style.display = 'block'
            })
            .catch((data) => {
                console.warn('ERRO')
                console.log(data)
            })

    }

    maskfields(masks, max, event) {
        var c = event.target;
        var v = c.value.replace(/\D/g, '');
        var m = c.value.length > max ? 1 : 0;
        VMasker(c).unMask();
        VMasker(c).maskPattern(masks[m]);
        c.value = VMasker.toPattern(v, masks[m]);
    }
    
    validateField() {
        
        const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

        let error = 0

        if(this.fieldName.value == '') {
            error++
            this.fieldName.parentNode.classList.add('error-field')
        } else {
            this.fieldName.parentNode.classList.remove('error-field')
        }
        
        if(!this.fieldEmail.value.match(mailformat)) {
            error++
            this.fieldEmail.parentNode.classList.add('error-field')
            
        } else {
            this.fieldEmail.parentNode.classList.remove('error-field')
        }

        if(this.fieldPhone.value == '') {
            error++
            this.fieldPhone.parentNode.classList.add('error-field')
        } else {
            this.fieldPhone.parentNode.classList.remove('error-field')
        }
        
        if(this.fieldSubject.value == '') {
            error++
            this.fieldSubject.parentNode.classList.add('error-field')
        } else {
            this.fieldSubject.parentNode.classList.remove('error-field')
        }
        
        if(this.fieldMessage.value == '') {
            error++
            this.fieldMessage.parentNode.classList.add('error-field')
        } else {
            this.fieldMessage.parentNode.classList.remove('error-field')
        }

        if(error === 0) {
            this.sendForm()
            this.errorForm.style.display = 'none';
        } else {
            this.errorForm.style.display = 'block';
        }

    }

    events() {

        VMasker(this.fieldPhone).maskPattern(this.telMask[0]);
        this.fieldPhone.addEventListener('input', this.maskfields.bind(undefined, this.telMask, 14), false);

        this.btnSubmitForm.addEventListener('click', this.validateField.bind(this))
        
    }

}